import React from 'react';
import MainMenu from './components/MainMenu';
import { Row } from 'react-bootstrap';
import SidebarWrapper from './components/Sidebar/SidebarWrapper';
import { Outlet } from 'react-router';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import EnsureAuthToken from './Shared/Auth/EnsureAuthToken';

function App() {

	// redirect to login if not authenticated
	useMsalAuthentication(InteractionType.Redirect);

	return (
		<div>
			<AuthenticatedTemplate>
				<EnsureAuthToken>
					<>
						<MainMenu />
						<div className="container-fluid">
							<Row className="flex-nowrap">
								<SidebarWrapper />
								<main className="col">
									<Outlet />
								</main>
							</Row>
						</div>
					</>
				</EnsureAuthToken>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<p>Redirecting to login...</p>
			</UnauthenticatedTemplate>
		</div>
	);
}

export default App;
