import { Button, Col, Row, Offcanvas } from 'react-bootstrap';
import './index.scss';
import { IcebergButton, IcebergIcon } from '@tradesolution/iceberg-ui-react';
import RightCol from './Components/RightCol';
import { useEffect, useState } from 'react';
import LeftCol, { Template, Templates } from './Components/LeftCol';
// import html2pdf from 'html2pdf.js';
import { toPng } from "html-to-image";
import jsPDF from 'jspdf';
import { ContactInfo, DesignData, TemplateData } from './TemplateData';
import Template1 from './templates/Template1';
import { ProductData } from '../../components/ProductTable';
import { useMsal } from '@azure/msal-react';
import { generateBarcode } from '../../Shared/BarcodeGenerator';
import LogRocket from 'logrocket';
import { useSelectedCompanyContext } from '../../Shared/SelectedCompanyContext';

const Designer = () => {

	const { accounts } = useMsal();
	const name = accounts[0] && accounts[0].name;
	const email = accounts[0] && accounts[0].username;

	const { selectedCompany } = useSelectedCompanyContext();

	const [templateData, setTemplateData] = useState<TemplateData>({
		designData: {
			title: 'Eksempel tittel',
		},
		productData: [],
		contactInfo: {
			email: email ?? '',
			name: name ?? '',
			phone: localStorage.getItem('phoneNumber') || '',
		}
	});

	// reset product data and logo image when selectedTsKunde changes
	useEffect(() => {
		setTemplateData({ ...templateData, productData: [], designData: { ...templateData.designData, logoFileName: undefined } });
	}, [selectedCompany]);

	const handleDesignDataChanged = (data: DesignData): void => {
		setTemplateData({ ...templateData, designData: data });
	}

	/* const htmlToPdf = (event: any): void => {
		// Access the html element
		const templateElement: any = document.getElementById('template');

		const opt = {
			margin:       0,
			filename:     'document.pdf',
			image:        { type: 'png' },
			html2canvas:  { scale: 2 },
			jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
		  };

		// Use html2pdf to create a PDF from the content
		html2pdf()
			.set(opt)
			.from(templateElement)
			.save();
	} */

	const [generatingPdf, setGeneratingPdf] = useState(false);

	const htmlToImageToPdf = (event: any): void => {

		setGeneratingPdf(true);

		LogRocket.track('Download PDF', {
			templateId: selectedTemplate.id,
			tsKundeNavn: selectedCompany?.name,
			fontColor: templateData.designData.fontColor,
			backgroundColor: templateData.designData.backgroundColor,
			fontFamily: templateData.designData.fontFamily,
			usedLogo: templateData.designData.logoFileName ? true : false,
			usedBackgroundImage: templateData.designData.backgroundImageUrl ? true : false,
			usedContactInfoName: templateData.contactInfo.name ? true : false,
			usedContactInfoEmail: templateData.contactInfo.email ? true : false,
			usedContactInfoPhone: templateData.contactInfo.phone ? true : false,
		});
		// Access the html element
		const templateElement: any = document.getElementById('template');

		// to get satisfactory resolution, we need to scale the canvas
		const scale = 3;

		toPng(templateElement, { canvasHeight: templateElement.offsetHeight * scale, canvasWidth: templateElement.offsetWidth * scale })
			.then((png) => {

				console.log('png', png);
				console.log(png.length);

				const startTime = performance.now()

				try {
					const pdf = new jsPDF({
						orientation: 'portrait',
						unit: 'px',
						format: [templateElement.offsetWidth, templateElement.offsetHeight]
					});

					pdf.addImage(png, 'PNG', 0, 0, templateElement.offsetWidth, templateElement.offsetHeight, undefined, 'MEDIUM');
					pdf.save('document.pdf');
				} catch (error) {
					console.error('Error generating PDF:', error);
				}

				const endTime = performance.now()

				console.log(`Call to doSomething took ${endTime - startTime} milliseconds`);

				setGeneratingPdf(false);
			});
	}

	const [selectedTemplate, setSelectedTemplate] = useState<Template>(Templates.find(o => o.id === 1) as Template);

	//mobile-version

	const [showLeftDrawer, setShowLeftDrawer] = useState(false);
	const [showRightDrawer, setShowRightDrawer] = useState(false);

	const handleTemplateSelected = (o: Template): void => {
		setSelectedTemplate(o);
	}

	const handleProductDataChanged = (data: ProductData[]): void => {
		const data2 = data.map((d, index) => {
			if (!d.strekkodeBase64Url) {
				d.strekkodeBase64Url = generateBarcode(d.epdNr);
			}
			return d;
		});
		setTemplateData({ ...templateData, productData: data2 });
	}

	const handleContactInfoChanged = (contactInfo: ContactInfo): void => {
		setTemplateData({ ...templateData, contactInfo: contactInfo });
	}

	const handleAngleSelected = (gtin: string, angle: string): void => {

		// update the angle for the product with the given gtin
		const productData = templateData.productData.map((product) => {
			if (product.basegtins[0] === gtin) {
				product.angle = angle;
			}
			return product;
		});
		setTemplateData({ ...templateData, productData: productData });
	}

	return (
		<div>
			<Row className='mt-3 mb-3'>
				<Col>
					<h3>Designer</h3>
				</Col>
				<Col className='text-end'>
					<IcebergButton icon='export' variant='outline-primary' isLoading={generatingPdf} onClick={htmlToImageToPdf}>Last ned PDF</IcebergButton>
				</Col>
			</Row>
			{/* Main content*/}
			<Row>
				{/*LeftCol for Desktop */}
				<Col md={2} className='d-none d-xxl-block'>
					<LeftCol
						onTemplateSelected={handleTemplateSelected}
						onProductDataChanged={handleProductDataChanged}
						productData={templateData.productData}
						onAngleSelected={handleAngleSelected}
					/>
				</Col>
				{/* Center Template */}
				<Col className='template-col' align="center">
					{templateData && <Template1 templateId={selectedTemplate?.id} templateData={templateData} />}
				</Col>
				{/* RightCol for Desktop */}
				<Col md={2} className='d-none d-xxl-block'>
					<RightCol onDesignDataChanged={handleDesignDataChanged} contactInfo={templateData.contactInfo} onContactInfoChanged={handleContactInfoChanged} />
				</Col>
			</Row>
			{/* Mobile Buttons */}
			<div className="d-xxl-none fixed-bottom d-flex justify-content-around gap-2 bg-white p-3">
				{/* Button to open Left Drawer */}
				<IcebergButton icon="document" className='w-50' variant='outline-primary' onClick={() => setShowLeftDrawer(true)}>Maler og produkter</IcebergButton>

				{/* Button to open Right Drawer */}
				<IcebergButton icon="brush" className='w-50' variant='outline-primary' onClick={() => setShowRightDrawer(true)}>Farger og logo</IcebergButton>
			</div>
			{/* Left Drawer */}
			<Offcanvas
				show={showLeftDrawer}
				onHide={() => setShowLeftDrawer(false)}
				placement="start" // Slide in from the left
			>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Maler og produkter</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<LeftCol
						onTemplateSelected={handleTemplateSelected}
						onProductDataChanged={handleProductDataChanged}
						productData={templateData.productData}
						onAngleSelected={handleAngleSelected}
					/>
				</Offcanvas.Body>
			</Offcanvas>

			{/* Right Drawer */}
			<Offcanvas
				show={showRightDrawer}
				onHide={() => setShowRightDrawer(false)}
				placement="end" // Slide in from the right
			>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Farger og logo</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<RightCol
						onDesignDataChanged={handleDesignDataChanged}
						contactInfo={templateData.contactInfo}
						onContactInfoChanged={handleContactInfoChanged}
					/>
				</Offcanvas.Body>
			</Offcanvas>



		</div>
	);
}

export default Designer;
