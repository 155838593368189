import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { IcebergProductMenu, IcebergIcon, IcebergCustomerPicker, IcebergNotifications } from '@tradesolution/iceberg-ui-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../Shared/Auth/authConfig';
import { useEffect, useState } from 'react';
import LogRocket from 'logrocket';
import CustomerGlnPicker from '../CustomerGlnPicker';
import './index.scss';


const MainMenu = () => {

	const [isNavbarExpanded, setIsNavbarExpanded] = useState<boolean>(false);
	const { instance, accounts } = useMsal();
	const name = accounts[0] && accounts[0].name;

	const getFirstName = (fullname: string | undefined): string => {
		if (!fullname) {
			return "";
		}
		return fullname.split(' ')[0];
	};

	const handleLogin = (i: IPublicClientApplication) => {
		i.loginRedirect(loginRequest).catch(e => {
			console.error(e);
		});
	}

	const handleLogout = () => {
		instance.logoutRedirect().catch(e => {
			console.error(e);
		});
	}

	// for logrocket 
	useEffect(() => {
		if (name)
			LogRocket.identify(name);
	}, [name]);

	return (
		<Navbar collapseOnSelect expand="lg" bg='info-dark' sticky='top' style={{ padding: "0.2rem 0" }} className='navbar-dark'>
			<Container fluid>
				<Navbar.Brand className='text-decoration-none' href="/">
					<IcebergIcon icon='picasso' size="lg" />
					<span className='ms-3'>Picasso</span>
				</Navbar.Brand>

				<Navbar.Toggle aria-controls="navbarScroll" onClick={() => setIsNavbarExpanded(!isNavbarExpanded)} style={{ cursor: 'pointer' }} className="custom-navbar-toggle">
					<IcebergIcon icon={isNavbarExpanded ? 'close' : 'menu'} color="white" size="24px" cursor='pointer' />
				</Navbar.Toggle>

				<Navbar.Collapse id="navbarScroll" role="navigation">
					<Nav className="me-auto d-lg-none">
						<NavDropdown className='noCaret'
							title={<IcebergIcon icon='meatballs' />}
						>
							<IcebergProductMenu />
						</NavDropdown>

						<Nav.Link href="/">Designer</Nav.Link>
						<Nav.Link href="/barcode">Barcode playground</Nav.Link>
						<Nav.Link href="/tablegenerator">Table playground</Nav.Link>
					</Nav>
					<Nav className="ms-auto">
						<NavDropdown
							id="dropdownUserMenu"
							align="end"
							title={
								<span style={{ marginLeft: '0.5em' }}>{getFirstName(name)}</span>
							}
						>
							<NavDropdown.Item onClick={handleLogout}>
								<span>
									<strong>Logg av</strong>
								</span>
							</NavDropdown.Item>
						</NavDropdown>
						<CustomerGlnPicker />
						<IcebergNotifications />
					</Nav>
				</Navbar.Collapse >
			</Container >
		</Navbar >
	);
}

export default MainMenu;